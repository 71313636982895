import React, { FC, useState, useCallback, useEffect } from 'react'
import styled, { css, useTheme } from 'styled-components'
import exclusiveSquare from '../assets/images/exclusive-square.svg'

interface SliderProps {
  interval?: number
  children: JSX.Element[]
}

let timeoutRef

export const Slider: FC<SliderProps> = ({ interval = 5000, children }) => {
  const [slide, setSlide] = useState(0)

  const increment = useCallback(() => {
    setSlide((i) => (i === children.length - 1 ? 0 : i + 1))
    clearTimeout(timeoutRef)
    timeoutRef = setTimeout(increment, interval)
  }, [children.length, interval])

  const decrement = useCallback(() => {
    setSlide((i) => (i === 0 ? children.length - 1 : i - 1))
    clearTimeout(timeoutRef)
    timeoutRef = setTimeout(increment, interval)
  }, [children.length, increment, interval])

  useEffect(() => {
    timeoutRef = setTimeout(increment, interval)

    return () => {
      clearTimeout(timeoutRef)
    }
  }, [increment, interval])

  return (
    <SliderSection>
      {children.map((slideElem, i) => (
        <Slide key={i} active={i === slide}>
          {slideElem}
        </Slide>
      ))}

      <div className="slider__controls">
        <ArrowButton prev onClick={decrement} />
        <ArrowButton next onClick={increment} />
      </div>

      <SliderNav>
        {(() => {
          const content = []
          for (let i = 0; i < children.length; i++) {
            content.push(
              <Dot key={i} active={slide === i} onClick={() => setSlide(i)} />
            )
          }
          return content
        })()}
      </SliderNav>
    </SliderSection>
  )
}

const SliderSection = styled.section`
  height: calc(100vh - 5rem);
  position: relative;
  margin: auto;
`

interface SlideProps {
  active: boolean
}
const Slide = styled.div<SlideProps>`
  overflow: hidden;
  display: none;
  width: 100%;
  height: 100%;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({ active }) =>
    active &&
    css`
      display: block;
    `}

  @-webkit-keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
`

export const SlideCaption: FC = ({ children }) => (
  <SlideCaptionStyled>
    <SlideCaptionContent>{children}</SlideCaptionContent>
  </SlideCaptionStyled>
)

const SlideCaptionStyled = styled.div`
  padding: 6rem 0 5rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.85),
    rgba(255, 255, 255, 1)
  );
`

const SlideCaptionContent = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: fit-content;
    max-width: 15rem;
    margin: 0 auto;
    font-family: ${theme.typography.raleway};
    font-weight: 900;
    font-size: 2rem;
    color: ${theme.colors.brand};
    letter-spacing: 0.05rem;

    small {
      display: block;
      font-size: 1.5rem;
      font-weight: 500;
    }

    @media screen and (min-width: 500px) {
      max-width: 19.5rem;
    }

    ${theme.media.min('tablet')} {
      max-width: 36.25rem;
    }

    &:before {
      display: block;
      content: ' ';
      background-image: url(${exclusiveSquare});
      background-size: 55px 55px;
      height: 55px;
      width: 55px;
      position: absolute;
      top: -25px;
      left: -40px;
    }
  `}
`

interface ArrowButtonProps {
  prev?: boolean
  next?: boolean
  onClick: () => void
}
const ArrowButton: FC<ArrowButtonProps> = ({ prev, next, onClick }) => {
  const {
    colors: { brand },
  } = useTheme()
  return (
    <StyledArrowButton onClick={onClick} prev={prev} next={next}>
      {prev && (
        <svg
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.5547 1L1.9998 30.5549L31.5547 57.439"
            stroke={brand}
            strokeWidth="2"
          />
        </svg>
      )}

      {next && (
        <svg
          width="61"
          height="60"
          viewBox="0 0 61 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.2195 58.7744L58.7744 29.2195L29.2195 2.33545"
            stroke="#1A6B93"
            strokeWidth="2"
          />
        </svg>
      )}
    </StyledArrowButton>
  )
}

interface StyledArrowButtonProps {
  prev?: boolean
  next?: boolean
}
const StyledArrowButton = styled.button<StyledArrowButtonProps>`
  ${({ next }) => css`
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 1rem 0 1rem 1rem;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;

    @media screen and (max-width: 720px) {
      display: none;
    }

    :hover {
      background-color: rgba(255, 255, 255, 0.7);
    }

    ${next &&
    css`
      right: 0;
      border-radius: 3px 0 0 3px;
      padding: 1rem 1rem 1rem 0;
    `}
  `}
`

const SliderNav = styled.div`
  width: 100%;
  position: absolute;
  bottom: 2rem;
  text-align: center;
`

interface DotProps {
  active: boolean
}
const Dot = styled.span<DotProps>`
  ${({ theme, active }) => css`
    cursor: pointer;
    height: 0.5rem;
    width: 1.875rem;
    margin: 0 0.5rem;
    border: 2px solid ${theme.colors.accent};
    display: inline-block;
    transition: background-color 0.6s ease;

    &:hover {
      background: ${theme.colors.accent};
    }
    ${active &&
    css`
      background: ${theme.colors.accent};
    `}
  `}
`
